import React from 'react';

export const TableRow = ({ label, value }) => {
  if (!value) return null;
  return (
    <div className='flex py-3 md:py-4 text-base leading-base md:text-xl'>
      <span className='w-32 md:w-64'>{label}</span>
      <span>{value}</span>
    </div>
  );
};
