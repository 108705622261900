import React from 'react';

export const QuantitySelector = ({ inc, dec, qty }) => {
  return (
    <div className='flex gap-x-3'>
      <div onClick={dec} className='cursor-pointer'>
        -
      </div>
      <span>{qty}</span>
      <div onClick={inc} className='cursor-pointer'>
        +
      </div>
    </div>
  );
};
