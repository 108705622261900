import React from 'react';
import { useParams } from '@reach/router';

import { clearSeason, useMembership } from '../context';

import { PortableTextBlock } from '@components/modules/portableTextBlock';

const Confimation = ({ description }) => {
  const { eventId } = useParams();
  const [state, membershipDispatch] = useMembership();

  React.useEffect(() => {
    clearSeason(membershipDispatch);
  }, []);

  return (
    <React.Fragment>
      <div className='px-gutter grid grid-cols-12 pb-gutter'>
        {/* Side Bar */}
        <div className='col-span-12 md:col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-3xl font-medium mb-4'>5. Confirmation</h2>
          </div>
        </div>
        {/* Main Content */}
        <div className='col-span-12 md:col-span-7 md:col-start-6 grid gap-y-6'>
          <PortableTextBlock text={description} className='prose' />
          <span className='text-base md:text-lg font-medium'>
            Order Number: {eventId}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Confimation;
