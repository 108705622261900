import React from 'react';
import { graphql } from 'gatsby';

import { Header } from '@components/nav/header';
import { MembershipProvider } from '@components/membershipForm/context';

import { PortableTextBlock } from '@components/modules/portableTextBlock';
import { Router } from '@reach/router';

import StepOne from '@components/membershipForm/steps/stepOne';
import StepTwo from '@components/membershipForm/steps/stepTwo';
import StepThree from '@components/membershipForm/steps/stepThree';
import StepFour from '@components/membershipForm/steps/stepFour';
import StepFive from '@components/membershipForm/steps/stepFive';
import Confirmation from '@components/membershipForm/steps/confirmation';
import { PageMeta } from '@components/pageMeta';

const MembershipForm = ({ data: { form } }) => {
  return (
    <MembershipProvider>
      <PageMeta {...form.meta} />
      <Header />
      <div className='px-gutter max-w-none md:prose-lg pt-24 pb-14 md:pb-44 text-black'>
        <h1 className='font-medium !m-0 text-4xl leading-4xl md:leading-[78px] md:!text-[65px]'>
          {form.title}
        </h1>
        <PortableTextBlock
          text={form.desc}
          className='max-w-[562px] w-full text-base mt-4'
        />
      </div>
      <Router>
        <StepOne
          path='/season-subscription/select'
          options={form.subscriptions}
        />
        <StepTwo
          path='/season-subscription/tickets'
          options={form.subscriptions}
        />
        <StepThree
          path='/season-subscription/donations'
          description={form.donationDesc}
        />
        <StepFour path='/season-subscription/details' />
        <StepFive
          path='/season-subscription/review'
          paymentDescription={form.paymentInfo}
        />
        <Confirmation
          path='/season-subscription/:eventId'
          description={form.confirmationText}
        />
      </Router>
    </MembershipProvider>
  );
};

export default MembershipForm;

export const formQuery = graphql`
  query membershipQuery {
    form: sanityMembershipForm {
      meta {
        ...MetaCardFields
      }
      id
      desc: _rawDescription(resolveReferences: { maxDepth: 100 })
      donationDesc: _rawDonationDescription(
        resolveReferences: { maxDepth: 100 }
      )
      confirmationText: _rawConfimationText(
        resolveReferences: { maxDepth: 100 }
      )
      paymentInfo: _rawPaymentInfo(resolveReferences: { maxDepth: 100 })
      title
      subscriptions {
        ... on SanityMembershipSeries {
          _type
          _key
          info: _rawInfoPopup(resolveReferences: { maxDepth: 100 })
          title
          concerts
          priceRange
          isMatinee
          ticketTypes {
            _key
            title
            price
          }
          seatingTypes {
            id
            title
            ticketTypes {
              _key
              title
              price
            }
          }
          venue {
            title
            location
            seatingMap {
              image {
                ...ImageWithPreview
              }
            }
          }
        }
        ... on SanityMembershipPackage {
          _type
          _key
          info: _rawInfoPopup(resolveReferences: { maxDepth: 100 })
          title
          concerts
          priceRange
          ticketTypes {
            _key
            title
            price
          }
        }
      }
    }
  }
`;
