import React from 'react';
import { navigate } from '@reach/router';
import { loadStripe } from '@stripe/stripe-js';

const storageKey = 'localOrder';

// Setup Context
const MembershipContext = React.createContext();
MembershipContext.displayName = 'Membership Provider';

// Initial State Object
const initialState = {
  checkoutLoading: false,
  series: [],
  packages: [],
  customerDetails: {},
  donationInfo: {},
  totals: {
    subtotal: 0,
    tax: 0,
    grandTotal: 0,
  },
};

// Handle Mmebership Checkout
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

function arrayConvert(data) {
  if (!data) {
    return [];
  }
  return typeof data === 'string' ? [data] : [...data];
}

function membershipReducer(state, action) {
  switch (action.type) {
    case 'update subscriptions': {
      const series = arrayConvert(action.series).map((item) => {
        return {
          _key: item,
        };
      });
      const packages = arrayConvert(action.packages).map((item) => {
        return {
          _key: item,
        };
      });
      return {
        ...state,
        series,
        packages,
      };
    }
    case 'update series tickets': {
      const { _key, ...rest } = action.updates;
      let newItems = [...state.series];

      const seriesIndex = state.series.findIndex((item) => item._key === _key);

      seriesIndex > -1
        ? (newItems[seriesIndex] = {
            ...newItems[seriesIndex],
            ...rest,
          })
        : newItems.push({
            _key,
            ...rest,
          });
      return {
        ...state,
        series: [...newItems],
      };
    }
    case 'update package tickets': {
      const { _key, ...rest } = action.updates;
      let newItems = [...state.packages];

      const seriesIndex = state.packages.findIndex(
        (item) => item._key === _key,
      );

      seriesIndex > -1
        ? (newItems[seriesIndex] = {
            ...newItems[seriesIndex],
            ...rest,
          })
        : newItems.push({
            _key,
            ...rest,
          });

      return {
        ...state,
        packages: [...newItems],
      };
    }
    case 'update donations': {
      return {
        ...state,
        donationInfo: { ...action.updates },
      };
    }
    case 'update details': {
      return {
        ...state,
        customerDetails: { ...action.updates },
      };
    }
    case 'start checkout': {
      return {
        ...state,
        checkoutLoading: true,
      };
    }
    case 'end checkout': {
      return {
        ...state,
        checkoutLoading: false,
      };
    }
    case 'clear season': {
      return {
        ...initialState,
      };
    }
    case 'update totals': {
      let seriesTotal = 0;
      let packageTotal = 0;

      // Taly up series tickets
      state.series.map(({ tickets }) => {
        tickets &&
          tickets.map((item) => {
            const lineTotal = item.price * item.qty;
            seriesTotal += lineTotal;
          });
      });
      // Tally up packages
      state.packages.map(({ tickets }) => {
        tickets &&
          tickets.map((item) => {
            const lineTotal = item.price * item.qty;
            packageTotal += lineTotal;
          });
      });
      let donationsTotal = 0;
      const { generalDonation, concertanteDonation } = state.donationInfo || {};

      if (!!generalDonation && !isNaN(generalDonation)) {
        donationsTotal += generalDonation;
      }
      if (!!concertanteDonation && !isNaN(concertanteDonation)) {
        donationsTotal += concertanteDonation;
      }

      // Calculate Total
      const grandTotal = seriesTotal + packageTotal + donationsTotal;

      return {
        ...state,
        totals: {
          ...state.totals,
          grandTotal,
        },
      };
    }
    case 'add series seating preference': {
      const { _key, ...rest } = action.updates;
      console.log(action.updates);

      const newItems = [...state.series];

      const seriesIndex = state.series.findIndex((item) => item._key === _key);

      // If we cant find the series bail out
      if (seriesIndex < 0) {
        return {
          ...state,
        };
      }

      newItems[seriesIndex] = {
        ...state.series[seriesIndex],
        ...rest,
      };

      return {
        ...state,
        series: [...newItems],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
// hooks
function useMembership() {
  const context = React.useContext(MembershipContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a Membership Provider`);
  }
  return context;
}

// got this idea from Dan and I love it:
// https://twitter.com/dan_abramov/status/1125773153584676864
function addSeriesTickets(dispatch, updates) {
  dispatch({ type: 'update series tickets', updates });
  dispatch({ type: 'update totals' });
}
function addPackageTickets(dispatch, updates) {
  dispatch({ type: 'update package tickets', updates });
  dispatch({ type: 'update totals' });
}

function addDonations(dispatch, updates) {
  dispatch({ type: 'update donations', updates });
  dispatch({ type: 'update totals' });
}

function clearDonations(dispatch) {
  dispatch({ type: 'update donations', updates: {} });
  dispatch({ type: 'update totals' });
}
function clearSeason(dispatch) {
  dispatch({ type: 'clear season' });
}

// Checkout functions
async function bookSeasonTransfer(dispatch, season) {
  try {
    dispatch({ type: 'start checkout' });
    const { confirmationId } = await fetch(
      '/.netlify/functions/book-season-transfer',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(season),
      },
    ).then((res) => res.json());

    dispatch({ type: 'end checkout' });
    return navigate(`/season-subscription/${confirmationId}`);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}
async function bookSeasonCheckout(dispatch, season) {
  try {
    dispatch({ type: 'start checkout' });

    const { sessionId } = await fetch(
      '/.netlify/functions/book-season-checkout',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(season),
      },
    ).then((res) => res.json());

    const stripe = await stripePromise;

    dispatch({ type: 'end checkout' });

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

const isBrowser = typeof window !== 'undefined';

const MembershipProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    membershipReducer,
    initialState,
    (initial) =>
      isBrowser
        ? JSON.parse(localStorage.getItem(storageKey)) || initial
        : initial,
  );
  const value = [state, dispatch];

  React.useEffect(() => {
    // This is a side-effect and belongs in an effect
    if (isBrowser) {
      localStorage.setItem(storageKey, JSON.stringify(state));
    }
  }, [state]);

  return (
    <MembershipContext.Provider value={value}>
      {children}
    </MembershipContext.Provider>
  );
};

export {
  MembershipProvider,
  useMembership,
  addSeriesTickets,
  addDonations,
  addPackageTickets,
  bookSeasonTransfer,
  bookSeasonCheckout,
  clearDonations,
  clearSeason,
};
