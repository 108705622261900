import React from 'react';
import { motion } from 'framer-motion';

import { useMembership } from '../context';

const SeasonTotal = () => {
  const [{ totals }] = useMembership();

  return (
    <motion.div className='bg-red text-white px-gutter py-2 fixed bottom-0 left-0 flex justify-between w-full text-3xl font-semibold'>
      <h4>Grand Total:</h4>
      <h4>${totals.grandTotal.toFixed(2)}</h4>
    </motion.div>
  );
};

export default SeasonTotal;
