import React from 'react';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'gatsby-plugin-sanity-image';

import { addSeriesTickets, useMembership } from '../context';

import { TableRow } from '../ui/tableRow';
import TicketsRow from '../ui/ticketsRow';

import TicketFormActions from './ticketFormActions';

const SeatingSelect = ({
  register,
  id,
  title,
  ticketTypes,
  setTickets,
  series,
  selectedSeating,
  clearErrors,
}) => {
  const selected =
    selectedSeating && selectedSeating.some((item) => item === id);
  return (
    <div className='flex gap-x-3 md:gap-x-10'>
      <div>
        <input
          type='checkbox'
          value={id}
          name='seatingType'
          className='styled-checkbox mt-1 md:mt-4'
          {...register('seatingType')}
        />
      </div>
      <div className='flex-grow'>
        <h3 className='text-2xl leading-2xl md:leading-5xl md:text-5xl font-medium flex pb-4'>
          {title}
        </h3>
        <div className='border-t border-b border-black border-opacity-50 w-full '>
          <AnimatePresence exitBeforeEnter>
            {selected ? (
              <motion.div
                className='grid overflow-hidden divide-y divide-black divide-opacity-50'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                {ticketTypes.map((item) => (
                  <TicketsRow
                    {...item}
                    seatId={id}
                    setTickets={setTickets}
                    series={series}
                    seating={title}
                    clearErrors={clearErrors}
                  />
                ))}
              </motion.div>
            ) : (
              <motion.div
                className=''
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                {ticketTypes.length > 1 ? (
                  <TableRow
                    label='Prices:'
                    value={`$${ticketTypes[0].price} - $${
                      ticketTypes[ticketTypes.length - 1].price
                    }`}
                  />
                ) : (
                  <TableRow label='Price' value={`$${ticketTypes[0].price}`} />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const SeriesTicketSelect = ({
  _key,
  index,
  activeEvent,
  increment,
  isFirst,
  isLast,
  title,
  venue,
  ticketTypes,
  seatingTypes,
  isMatinee,
}) => {
  const isActive = activeEvent === index;
  const [{ series }, membershipDispatch] = useMembership();

  const [tickets, setTickets] = React.useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const selectedSeating = watch('seatingType');
  const matineeDay = watch('matineeDay');

  React.useEffect(() => {
    addSeriesTickets(membershipDispatch, { _key, title, tickets });
  }, [tickets]);

  const [season] = useMembership();

  const onSubmit = (data) => {
    if (
      season.series[0]?.tickets.length === 0 ||
      (isMatinee && season.series[1]?.tickets.length === 0)
    ) {
      setError('noTickets', {
        type: 'manual',
        name: 'firstName',
        message: 'You must select a ticket',
      });
      return;
    }

    membershipDispatch({
      type: 'add series seating preference',
      updates: { _key, ...data },
    });
    if (isLast) {
      navigate('/season-subscription/donations');
    }
    if (!isLast) {
      increment(1);
    }
  };

  return (
    isActive && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='px-gutter grid grid-cols-12'>
        {/* Sidebar */}
        <div className='col-span-12 md:col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-xl md:text-3xl font-medium mb-4'>2. {title}</h2>
            {venue && venue.seatingMap && venue.seatingMap.image && (
              <div>
                <Image {...venue.seatingMap.image} alt='Seating Map' />
              </div>
            )}
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='col-span-12 md:col-span-7 md:col-start-6 mt-12 md:mt-0'>
          <h2 className='text-xl md:text-3xl font-medium leading-3xl mb-10'>
            Seating Types
          </h2>
          {!isMatinee && (
            <div className='grid gap-y-4 md:gap-y-10'>
              {seatingTypes.map((item) => (
                <SeatingSelect
                  {...item}
                  register={register}
                  setTickets={setTickets}
                  series={title}
                  selectedSeating={selectedSeating}
                  clearErrors={clearErrors}
                />
              ))}
            </div>
          )}
          {isMatinee && (
            <div className='grid gap-y-4'>
              {ticketTypes.map((item) => (
                <TicketsRow
                  {...item}
                  setTickets={setTickets}
                  series={title}
                  seating={matineeDay}
                  clearErrors={clearErrors}
                />
              ))}
            </div>
          )}
          {/* Matinee */}
          {isMatinee && (
            <div className='mt-10'>
              <h2 className='text-xl md:text-3xl font-medium leading-3xl mb-10'>
                Please indicate which day you would like to attend the concerts
                *
              </h2>
              <div className='flex gap-x-4 items-center'>
                <label className='text-lg flex gap-x-3 items-center font-medium'>
                  <input
                    type='radio'
                    value='Saturday'
                    className='styled-radio w-7 h-7'
                    {...register('matineeDay', { required: true })}
                    required
                  />
                  Saturday
                </label>
                <label className='text-lg flex gap-x-3 items-center font-medium'>
                  <input
                    type='radio'
                    value='Sunday'
                    className='styled-radio w-7 h-7'
                    {...register('matineeDay', { required: true })}
                    required
                  />
                  Sunday
                </label>
              </div>
            </div>
          )}
          {/* Seating Preferences */}
          <div className='mt-12'>
            <h3 className='text-xl md:text-3xl font-medium'>
              Seating Preferences
            </h3>
            <div className='grid gap-y-10 mt-10 md:mt-12'>
              {/* Repeat Seats */}
              <div>
                <legend className='text-xl md:text-3xl font-medium mb-7 md:mb-5 inline-block'>
                  If you are a renewing subscriber, would you like the same{' '}
                  {isMatinee ? "King's and Queen's" : 'Town Hall'} seats as last
                  year?
                </legend>
                <div className='flex gap-x-4 items-center'>
                  <label className='text-lg flex gap-x-3 items-center font-medium'>
                    <input
                      type='radio'
                      value='Yes'
                      className='styled-radio w-7 h-7'
                      {...register('seatingPreferences.repeatSeats')}
                    />
                    Yes
                  </label>
                  <label className='text-lg flex gap-x-3 items-center font-medium'>
                    <input
                      type='radio'
                      value='No'
                      className='styled-radio w-7 h-7'
                      {...register('seatingPreferences.repeatSeats')}
                    />
                    No
                  </label>
                </div>
              </div>
              {/* Seating Area */}
              <div>
                <label className='form-label flex w-full mt-2 md:mt-2 text-xl md:text-3xl'>
                  Preferred Seating Area
                </label>
                <input
                  type='text'
                  className='styled-input w-full'
                  placeholder={
                    isMatinee
                      ? 'e.g. KQ7 - please see diagram to the left'
                      : 'e.g. G7 - please see diagram above'
                  }
                  {...register('seatingPreferences.area')}
                />
              </div>
              {/* Seating Area */}
              <div>
                <label className='form-label flex w-full text-xl md:text-3xl'>
                  Further Seating Requirements
                </label>
                <input
                  type='text'
                  className='styled-input w-full'
                  placeholder='Enter seating requirements here'
                  {...register('seatingPreferences.requirements')}
                />
              </div>
            </div>
            {errors && errors.noTickets && (
              <p className='error-message py-2 text-center md:text-left mt-10'>
                {errors.noTickets.message}
              </p>
            )}
          </div>

          <TicketFormActions
            isFirst={isFirst}
            isLast={isLast}
            increment={increment}
            hasMargin
          />
        </form>
      </motion.div>
    )
  );
};

export default SeriesTicketSelect;
