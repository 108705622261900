import React from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';

import { useMembership } from '../context';
import SeasonTotal from '../ui/seasonTotal';
import { ArrowButton, ArrowButtonDiv } from '@components/ui/buttons';

const titles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof'];

const StepFour = () => {
  const [state, membershipDispatch] = useMembership();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const onSubmit = (updates) => {
    membershipDispatch({ type: 'update details', updates });
    navigate('/season-subscription/review');
  };

  return (
    <React.Fragment>
      <div className='px-gutter grid grid-cols-12'>
        <div className='col-span-12 md:col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-xl md:text-3xl font-medium mb-4'>
              4. Personal Information
            </h2>
          </div>
        </div>
        {/* Form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='col-span-12 md:col-span-7 md:col-start-6'>
          {/* Name */}
          <div className='grid'>
            <div className='mt-12 md:mt-0 flex flex-col md:flex-row gap-x-10 items-start'>
              <div className='styled-select w-full md:w-[unset]'>
                <select
                  {...register('title')}
                  className='styled-input !pt-0 w-full md:w-32'>
                  {titles.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className='flex-grow pt-5 md:pt-0 w-full'>
                <input
                  type='text'
                  className='styled-input w-full !pt-0'
                  placeholder='Full Name *'
                  {...register('name', { required: true })}
                />
                {errors.name && errors.name.type === 'required' && (
                  <span className='error-message'>This is required</span>
                )}
              </div>
            </div>
            {/* Email */}
            <div>
              <input
                type='email'
                className='styled-input w-full'
                placeholder='Email Address *'
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <span className='error-message'>This is required</span>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <span className='error-message'>Invalid email address</span>
              )}
            </div>
            {/* Address */}
            <div>
              <input
                type='address'
                className='styled-input w-full'
                placeholder='Postal Address *'
                {...register('address', {
                  required: true,
                })}
              />
              {errors.address && errors.address.type === 'required' && (
                <span className='error-message'>This is required</span>
              )}
            </div>
            {/* Postcode */}
            <div>
              <input
                type='postcode'
                className='styled-input w-full'
                placeholder='Postcode *'
                {...register('postcode', {
                  required: true,
                })}
              />
              {errors.postcode && errors.postcode.type === 'required' && (
                <span className='error-message'>This is required</span>
              )}
            </div>
            {/* Phone */}
            <div>
              <input
                type='text'
                className='styled-input w-full'
                placeholder='Phone *'
                {...register('phone', {
                  required: true,
                })}
              />
              {errors.phone && errors.phone.type === 'required' && (
                <span className='error-message'>This is required</span>
              )}
            </div>
          </div>
          {/* Actions */}
          <div className='flex justify-between py-20'>
            <ArrowButtonDiv
              reversed
              onClick={() => navigate('/season-subscription/donations')}>
              Back
            </ArrowButtonDiv>
            <ArrowButton type='submit'>Next</ArrowButton>
          </div>
        </form>
      </div>
      <SeasonTotal />
    </React.Fragment>
  );
};

export default StepFour;
