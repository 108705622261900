import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import cx from 'classnames';

import {
  useMembership,
  bookSeasonTransfer,
  bookSeasonCheckout,
} from '../context';
import { ArrowButton, ArrowButtonDiv } from '@components/ui/buttons';
import { InfoPopup } from '../ui/infoPopup';

const PersonalInfo = ({ address, email, name, phone, title, postcode }) => {
  return (
    <div className='grid gap-y-2 font-medium'>
      <h4 className='text-xl md:text-3xl mb-3 md:mb-0'>Personal Information</h4>
      <div className='text-lg'>
        <p>
          {title} {name}
        </p>
        <p>{address}</p>
        <p>{postcode}</p>
      </div>
      <div className='text-lg'>
        <p>
          <a href={email} target='_blank'>
            {email}
          </a>
        </p>
        <p>
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
      </div>
    </div>
  );
};

const SummaryLineItem = ({
  _key,
  type,
  qty,
  price,
  series,
  seating,
  isPackage,
}) => {
  const linePrice = price * qty;
  return (
    <div key={_key} className='flex justify-between gap-x-4'>
      <span>
        {qty}x {series} - {!isPackage && `${seating} -`} {type}
      </span>
      <span className='flex items-end'>${linePrice.toFixed(2)}</span>
    </div>
  );
};
const DonationLineItem = ({ _key, label, amount, isAnonymous }) => {
  return (
    <div key={_key} className='flex justify-between gap-x-4'>
      <span>{label}</span>
      <span className='flex items-end'>${amount.toFixed(2)}</span>
    </div>
  );
};

const OrderSummary = ({ series, packages, donationInfo, totals }) => {
  const seriesTickets = series
    .map((series) => series.tickets.map((item) => item))
    .reduce((acc, val) => acc.concat(val), []);

  const packageTickets = packages
    .map((series) => series.tickets.map((item) => item))
    .reduce((acc, val) => acc.concat(val), []);

  const { generalDonation } = donationInfo || {};

  return (
    <div className='mt-10 md:mt-14'>
      <h4 className='text-xl mb-8 md:mb-10 md:text-3xl font-medium'>Summary</h4>
      <div className='grid gap-y-2 font-medium text-base md:text-lg'>
        {seriesTickets.map((item) => (
          <SummaryLineItem {...item} />
        ))}
        {packageTickets.map((item) => (
          <SummaryLineItem {...item} isPackage />
        ))}
        {generalDonation && (
          <DonationLineItem
            label={
              !donationInfo?.anonymous
                ? 'General Donation'
                : 'Anonymous Donation'
            }
            amount={generalDonation}
          />
        )}
      </div>
      <div className='flex justify-between font-bold border-t pt-6 md:pt-10 mt-10 text-base md:text-lg'>
        <span>Grand Total (Series subscription plus donations)</span>
        <span>${totals.grandTotal.toFixed(2)}</span>
      </div>
    </div>
  );
};

const StepFive = ({ paymentDescription }) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const [season, membershipDispatch] = useMembership();
  const {
    customerDetails,
    series,
    packages,
    donationInfo,
    totals,
    checkoutLoading,
  } = season || {};
  const { register, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      payBy: 'credit',
    },
  });

  const onSubmit = async ({ payBy }) => {
    console.log(season);
    if (payBy === 'credit') {
      // console.log(season);
      bookSeasonCheckout(membershipDispatch, season).catch(() => {
        // Ignore the error
      });
    } else {
      bookSeasonTransfer(membershipDispatch, season).catch(() => {
        // Ignore the error
      });
    }
  };

  return (
    <React.Fragment>
      <div className='px-gutter grid grid-cols-12'>
        {/* Side Bar */}
        <div className='col-span-12 md:col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-xl md:text-3xl font-medium mb-12 md:mb-4'>
              5. Order Summary
            </h2>
          </div>
        </div>
        <div className='col-span-12 md:col-span-7 md:col-start-6 grid gap-y-6'>
          {/* Personal Info */}
          <PersonalInfo {...customerDetails} />
          {/* Order Summary */}
          <OrderSummary
            series={series}
            packages={packages}
            totals={totals}
            donationInfo={donationInfo}
          />
          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className='text-xl md:text-3xl mb-10 md:mb-6 mt-16 font-medium'>
              Payment Options
            </h4>
            <div className='grid gap-y-4'>
              <label className='text-base md:text-lg flex gap-x-3 items-center font-medium'>
                <input
                  type='radio'
                  value='credit'
                  className='styled-radio w-7 h-7'
                  {...register('payBy')}
                />
                <span className='w-10/12 md:w-full'>
                  Pay by credit/ debit card
                </span>
              </label>
              <label className='text-base md:text-lg flex gap-x-3 items-center font-medium'>
                <input
                  type='radio'
                  value='transfer'
                  className='styled-radio w-7 h-7'
                  {...register('payBy')}
                />
                <span className='flex items-center gap-x-4'>
                  Pay by direct credit (bank transfer){' '}
                  <button
                    type='button'
                    onClick={() => setInfoOpen(true)}
                    className='h-7 w-7 border-2 rounded-full border-black font-bold text-lg flex items-center justify-center'>
                    i
                  </button>
                </span>
              </label>
            </div>
            {/* Actions */}
            <div className='flex justify-between py-20'>
              <ArrowButtonDiv
                reversed
                onClick={() => navigate('/season-subscription/details')}>
                Back
              </ArrowButtonDiv>
              <ArrowButton
                type='submit'
                className={cx({
                  'cursor-wait': checkoutLoading,
                })}
                disabled={checkoutLoading}>
                Complete Order
              </ArrowButton>
            </div>
          </form>
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        {infoOpen && (
          <InfoPopup
            popupOpen={infoOpen}
            setPopupOpen={setInfoOpen}
            info={paymentDescription}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

export default StepFive;
