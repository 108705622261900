import React from 'react';
import { motion } from 'framer-motion';

import { PortableTextBlock } from '@components/modules/portableTextBlock';
import { useScrollFreeze } from '@hooks/useScrollFreeze';

import Close from '@svg/close-black.svg';

export const InfoPopup = ({ popupOpen, setPopupOpen, info }) => {
  useScrollFreeze();
  return (
    <motion.div
      key={popupOpen}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='fixed top-0 left-0 w-full h-full bg-[#00000030] flex items-center justify-center'>
      <div className='min-h-[600px] bg-white px-8 md:px-16 text-black flex items-center relative max-w-[1000px] w-full mx-gutter'>
        <button
          type='button'
          onClick={() => setPopupOpen(false)}
          className='absolute top-0 md:top-4 right-0 md:right-10'>
          <Close className='w-1/2 md:w-full' />
        </button>
        <PortableTextBlock
          text={info}
          className='!max-w-screen-sm w-full prose md:prose-lg'
        />
      </div>
    </motion.div>
  );
};
