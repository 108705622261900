import React from 'react';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';

import { addPackageTickets, useMembership } from '../context';

import TicketFormActions from './ticketFormActions';
import TicketsRow from '../ui/ticketsRow';

const PackageTicketSelect = ({
  _key,
  index,
  title,
  activeEvent,
  increment,
  isFirst,
  isLast,
  ticketTypes,
}) => {
  const isActive = activeEvent === index;
  const [state, membershipDispatch] = useMembership();

  const [tickets, setTickets] = React.useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  React.useEffect(() => {
    addPackageTickets(membershipDispatch, { _key, title, tickets });
  }, [tickets]);

  const [season] = useMembership();

  const onSubmit = (data) => {
    if (season.packages[0]?.tickets.length === 0) {
      setError('noTickets', {
        type: 'manual',
        name: 'firstName',
        message: 'You must select a ticket',
      });
      return;
    }
    if (isLast) {
      navigate('/season-subscription/donations');
    }
    if (isFirst && !isLast) {
      increment(1);
    }
  };

  return (
    isActive && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='px-gutter grid grid-cols-12'>
        {/* Sidebar */}
        <div className='col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-3xl font-medium mb-4'>2. {title}</h2>
          </div>
        </div>
        {/* Form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='col-span-7 col-start-6'>
          <div>
            <h4 className='text-3xl font-medium flex pb-4'>Vouchers</h4>
            <div className='border-t border-b border-black border-opacity-50 py-2'>
              {ticketTypes.map((item) => (
                <TicketsRow
                  {...item}
                  series={title}
                  seating=''
                  setTickets={setTickets}
                  clearErrors={clearErrors}
                  isPackage
                />
              ))}
            </div>
            {errors && errors.noTickets && (
              <p className='error-message py-2 text-center md:text-left mt-10'>
                {errors.noTickets.message}
              </p>
            )}
          </div>
          <TicketFormActions
            isFirst={isFirst}
            isLast={isLast}
            increment={increment}
          />
        </form>
      </motion.div>
    )
  );
};

export default PackageTicketSelect;
