import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { navigate } from '@reach/router';

import { useMembership, clearDonations } from '../context';

//
import SeriesTicketSelect from '../ticketForms/seriesTicketSelect';
import PackageTicketSelect from '../ticketForms/packageTicketSelect';
import SeasontTotal from '../ui/seasonTotal';

const StepTwo = ({ options }) => {
  const [activeEvent, setActiveEvent] = useState(0);
  const [{ series, packages }, membershipDispatch] = useMembership();
  const selectedIDs = [...series, ...packages].map((item) => item._key);

  // If there is no selections bail out to series select
  if (!selectedIDs.length) {
    navigate('/season-subscription/select');
  }

  const increment = (d) =>
    setActiveEvent((prev) => (d < 0 ? prev - 1 : prev + 1));

  const events = options.filter((item) => selectedIDs.includes(item._key));

  React.useEffect(() => {
    clearDonations(membershipDispatch);
  }, []);

  return (
    <React.Fragment>
      <AnimatePresence exitBeforeEnter>
        {events.map(({ _type, ...item }, index) =>
          _type === 'membershipPackage' ? (
            <PackageTicketSelect
              {...item}
              index={index}
              activeEvent={activeEvent}
              increment={increment}
              isFirst={index === 0}
              isLast={index + 1 === events.length}
            />
          ) : (
            <SeriesTicketSelect
              {...item}
              index={index}
              activeEvent={activeEvent}
              increment={increment}
              isFirst={index === 0}
              isLast={index + 1 === events.length}
            />
          ),
        )}
      </AnimatePresence>
      <SeasontTotal />
    </React.Fragment>
  );
};

export default StepTwo;
