import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { navigate } from '@reach/router';
import NumberFormat from 'react-number-format';

import { useMembership, addDonations, clearDonations } from '../context';
import SeasonTotal from '../ui/seasonTotal';
import { ArrowButtonDiv, ArrowButton } from '@ui/buttons';
import { PortableTextBlock } from '@components/modules/portableTextBlock';

const StepThree = ({ description }) => {
  const [state, membershipDispatch] = useMembership();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  // Clear out donations on initial render
  React.useEffect(() => {
    clearDonations(membershipDispatch);
  }, []);
  // Add donations to the state
  React.useEffect(() => {
    const subscription = watch((value) =>
      addDonations(membershipDispatch, { ...value }),
    );

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    addDonations(membershipDispatch, { ...data });
    navigate('/season-subscription/details');
  };

  return (
    <React.Fragment>
      <div className='px-gutter grid grid-cols-12'>
        <div className='col-span-12 md:col-span-4'>
          <div className='sticky top-4'>
            <h2 className='text-3xl font-medium mb-6 md:mb-4'>
              3. Giving to the Orchestra
            </h2>
          </div>
        </div>
        {/* Form */}
        <div className='col-span-12 md:col-span-7 md:col-start-6'>
          <PortableTextBlock
            className='text-base md:prose-lg'
            text={description}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid gap-y-4 mb-10 mt-20 md:my-10'>
              {/* General Donations */}
              <div>
                <label className='form-label flex w-full'>Donations</label>

                <Controller
                  name='generalDonation'
                  control={control}
                  render={({
                    field: { onChange, onBlur, name, value, ref },
                  }) => (
                    <NumberFormat
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={(values) => onChange(values.floatValue)}
                      prefix='$'
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      ref={ref}
                      className='styled-input w-full'
                      placeholder='Enter amount here'
                    />
                  )}
                />
              </div>

              {/* Other */}
              <label className='text-lg flex justify-between gap-x-3 items-center mt-12 md:mt-12 font-medium'>
                <input
                  type='checkbox'
                  className='styled-radio w-7 h-7'
                  {...register('moreInfo')}
                />
                <span className='w-10/12 md:w-full'>
                  I would like more information about donating to the DSO.
                </span>
              </label>
              <label className='text-lg flex justify-between gap-x-3 items-center font-medium'>
                <input
                  type='checkbox'
                  className='styled-radio w-7 h-7'
                  {...register('anonymous')}
                />
                <span className='w-10/12 md:w-full'>
                  I would like to remain anonymous.
                </span>
              </label>
              {/* Actions */}
              <div className='flex justify-between pt-10 pb-20 md:py-20'>
                <ArrowButtonDiv
                  reversed
                  onClick={() => navigate('/season-subscription/tickets')}>
                  Back
                </ArrowButtonDiv>
                <ArrowButton type='submit'>Next</ArrowButton>
              </div>
            </div>
          </form>
        </div>
      </div>
      <SeasonTotal />
    </React.Fragment>
  );
};

export default StepThree;
