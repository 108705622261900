import React from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';

import { ArrowButton, ArrowButtonDiv } from '@ui/buttons';

const TicketFormActions = ({
  isFirst,
  isLast,
  increment,
  hasMargin = false,
}) => {
  return (
    <div
      className={'flex justify-between py-20 !text-xl md:text-3xl font-medium'}>
      {/* Back to Ticket Select */}
      {isFirst && (
        <ArrowButtonDiv
          onClick={() => navigate('/season-subscription/select')}
          reversed>
          Back
        </ArrowButtonDiv>
      )}
      {/* Go Back An Event */}
      {!isFirst && (
        <ArrowButtonDiv
          onClick={() => {
            increment(-1);
            window && window?.scrollTo(0, 0);
          }}
          reversed>
          Back
        </ArrowButtonDiv>
      )}
      <ArrowButton
        type='submit'
        onClick={() => window && window?.scrollTo(0, 0)}>
        Next
      </ArrowButton>
    </div>
  );
};

export default TicketFormActions;
