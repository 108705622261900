import React from 'react';

import { useInc } from '../../../hooks/useInc';
import { QuantitySelector } from '../ui/quantitySelector';

const TicketsRow = ({
  setTickets,
  title,
  price,
  _key,
  series,
  seating,
  clearErrors,
  seatId,
  isPackage,
}) => {
  const [qty, { dec, inc }] = useInc({
    maxValue: 30,
    minValue: 0,
    initial: 0,
    step: 1,
  });

  const ticketKey = seatId ? `${seatId}-${_key}` : _key;

  React.useEffect(() => {
    const ticketObject = {
      _key: ticketKey,
      type: isPackage ? null : title,
      series,
      seating,
      price,
      qty,
    };

    if (qty > 0) {
      clearErrors('noTickets');
      setTickets((prev) => {
        let newTicketItems = [...prev];
        const ticketIndex = prev.findIndex((item) => item._key === ticketKey);
        ticketIndex > -1
          ? (newTicketItems[ticketIndex] = ticketObject)
          : newTicketItems.push(ticketObject);
        return newTicketItems;
      });
    } else {
      setTickets((prev) => {
        let newTicketItems = [...prev];
        const ticketIndex = prev.findIndex((item) => item._key === ticketKey);
        if (ticketIndex > -1) {
          newTicketItems.splice(ticketIndex, 1);
        }
        return newTicketItems;
      });
    }
  }, [qty, seating]);

  return (
    <div className='grid grid-cols-3 py-2'>
      <div className='flex justify-between col-span-2 text-base md:text-xl w-full'>
        <h4>{title}:</h4>
        <h4>${price}</h4>
      </div>
      <div className='col-span-1 justify-self-end text-base md:text-xl'>
        <QuantitySelector inc={inc} dec={dec} qty={qty} />
      </div>
    </div>
  );
};

export default TicketsRow;
