import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { AnimatePresence } from 'framer-motion';

import { useMembership } from '../context';
import { ArrowButton } from '@ui/buttons';
import { TableRow } from '../ui/tableRow';
import { InfoPopup } from '../ui/infoPopup';
import cx from 'classnames';

const OptionPreview = ({
  _type,
  title,
  _key,
  priceRange,
  concerts,
  register,
  venue,
  setValue,
  clearErrors,
  info,
  i,
}) => {
  const [popupOpen, setPopupOpen] = useState(null);
  return (
    <>
      <div key={_key} className='flex gap-x-3 md:gap-x-10 items-start'>
        <div>
          {_type === 'membershipPackage' ? (
            <input
              type='checkbox'
              value={_key}
              name='packages'
              className='styled-checkbox mt-1 md:mt-4 hidden'
              {...register('packages', {
                onChange: (e) => {
                  clearErrors('noPackage');
                },
              })}
            />
          ) : (
            <input
              type='checkbox'
              value={_key}
              name='series'
              className='styled-checkbox mt-1 md:mt-4 hidden'
              {...register('series', {
                onChange: (e) => {
                  clearErrors('noPackage');
                },
              })}
            />
          )}
        </div>
        <div className='flex-grow'>
          <div className='flex gap-x-4 items-center pb-4'>
            <h2 className='text-2xl leading-2xl md:leading-5xl md:text-5xl font-medium flex'>
              {title}
            </h2>
            {info && (
              <button
                type='button'
                onClick={() => setPopupOpen(i)}
                className='h-7 w-7 border-2 rounded-full border-black font-bold text-lg flex items-center justify-center mt-3'>
                i
              </button>
            )}
          </div>
          <div className='grid grid-flow-row divide-y divide-black divide-opacity-30 border-t border-b border-black border-opacity-30'>
            <TableRow label='Concerts:' value={concerts} />
            <TableRow label='Prices:' value={priceRange} />
            <TableRow label='Venue:' value={venue && venue.title} />
          </div>
        </div>
      </div>

      <AnimatePresence exitBeforeEnter>
        {popupOpen === i && (
          <InfoPopup
            popupOpen={popupOpen}
            setPopupOpen={setPopupOpen}
            info={info}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const StepOne = ({ options }) => {
  const [state, membershipDispatch] = useMembership();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (
      (!data.packages && !data.series) ||
      (!data.packages && data.series.length < 1)
    ) {
      setError('noPackage', {
        type: 'manual',
        name: 'firstName',
        message: 'You must choose select a series or package',
      });
      return;
    }

    membershipDispatch({ type: 'update subscriptions', ...data });

    navigate('/season-subscription/tickets');
  };
  return (
    <div className='px-gutter grid grid-cols-12'>
      {/* Left Bar */}
      <div className='col-span-12 md:col-span-4'>
        <h2 className='text-xl leading-xl md:leading-3xl md:text-3xl font-medium'>
          1. Ticket Selections
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='col-span-12 md:col-span-8 mt-14 md:mt-0'>
        {/* Heading */}
        <h2 className='text-xl md:text-3xl font-medium mb-9 md:mb-12 flex'>
          Select your ticket subscription ticket type/s
        </h2>

        {/* Selections */}
        <div className='grid gap-y-10'>
          {options.map((option, i) => (
            <OptionPreview
              {...option}
              key={i}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              i={i}
            />
          ))}
          {errors && errors.noPackage && (
            <p className='error-message py-2 text-center md:text-left md:ml-10'>
              {errors.noPackage.message}
            </p>
          )}
        </div>

        {/* Actions */}
        <div className='py-20 flex justify-end text-xl md:text-3xl font-medium'>
          <ArrowButton type='submit'>Next</ArrowButton>
        </div>
        {/*  */}
      </form>
    </div>
  );
};

export default StepOne;
